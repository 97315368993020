import React, { Component } from 'react';
import '../App.css';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import blue from "@material-ui/core/colors/blue";

export default class Result extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        const { height, width, isFixed, glassNumber, fix, type } = this.props;
        const CustomTableCell = withStyles(theme => ({
            head: {
                backgroundColor: blue[500],
                color: theme.palette.common.white
            },
            body: {
                fontSize: 16
            }
        }))(TableCell);

        const styles = theme => ({
            root: {
                width: "100%",
                marginTop: theme.spacing.unit * 3,
                overflowX: "auto"
            },
            table: {
                minWidth: 700
            },
            row: {
                "&:nth-of-type(odd)": {
                    backgroundColor: theme.palette.background.default
                }
            }
        });

        let id = 0;
        function createData(name, value, count) {
            id += 1;
            return { id, name, value, count };
        }
        let rows = [];

        if(type === 1){
            if(!isFixed){
                rows = [
                    createData("边封", height, glassNumber * 2),
                    createData("下轨", (width - 2.8).toFixed(1), glassNumber),
                    createData("长光企", (height - 5.8).toFixed(1), glassNumber),
                    createData("短光企", (height - 6.8).toFixed(1), glassNumber),
                    createData("长勾企", (height - 5.8).toFixed(1), glassNumber),
                    createData("短勾企", (height - 6.8).toFixed(1), glassNumber),
                    createData("上方", ((width - 10)/2).toFixed(1), glassNumber * 2),
                    createData("下方", ((width - 10)/2).toFixed(1), glassNumber),
                    createData("平上滑", (width - 2.8).toFixed(1), glassNumber),
                    createData("窗芯玻璃高度", (height - 15.1).toFixed(1), glassNumber),
                    createData("窗芯玻璃宽度", ((width - 10)/2 - 0.8).toFixed(1), glassNumber)
                ];
            }else{
                rows = [
                    createData("边封", height, glassNumber * 2),
                    createData("长光企", (height - fix - 7.8).toFixed(1), glassNumber),
                    createData("短光企", (height - fix - 8.8).toFixed(1), glassNumber),
                    createData("长勾企", (height - fix - 7.8).toFixed(1), glassNumber),
                    createData("短勾企", (height - fix - 8.8).toFixed(1), glassNumber),
                    createData("上方", ((width - 10)/2).toFixed(1), glassNumber * 2),
                    createData("下方", ((width - 10)/2).toFixed(1), glassNumber),
                    createData("上固定", (width - 2.8).toFixed(1), glassNumber),
                    createData("中腰", (width - 2.8).toFixed(1), glassNumber),
                    createData("下轨", (width - 2.8).toFixed(1), glassNumber),
                    createData("盖板", (fix - 3.5).toFixed(1), glassNumber * 2),
                    createData("中柱", (fix - 3.5).toFixed(1), glassNumber),
                    createData("窗芯玻璃高度", (height - fix - 17.1).toFixed(1), "无"),
                    createData("窗芯玻璃宽度", ((width - 10)/2 - 0.8).toFixed(1), "无"),
                    createData("固定玻璃高度", (fix - 2.8).toFixed(1), "无"),
                    createData("不带中柱固定玻璃宽度", (width - 6.5).toFixed(1), "无"),
                    createData("带中柱固定玻璃宽度", ((width - 10)/2).toFixed(1), "无")
                ];
            }
        }else if(type === 2){
            if(!isFixed){
                rows = [
                    createData("边封", height, glassNumber * 2),
                    createData("下轨", (width - 3).toFixed(1), glassNumber),
                    createData("长光企", (height - 6).toFixed(1), glassNumber),
                    createData("短光企", (height - 7.5).toFixed(1), glassNumber),
                    createData("长勾企", (height - 6).toFixed(1), glassNumber),
                    createData("短勾企", (height - 7.5).toFixed(1), glassNumber),
                    createData("上方", ((width - 13.2)/2).toFixed(1), glassNumber * 2),
                    createData("中方", ((width - 13.2)/2).toFixed(1), glassNumber),
                    createData("下方", ((width - 13.2)/2).toFixed(1), glassNumber),
                    createData("平上滑", (width - 3).toFixed(1), glassNumber),
                    createData("窗芯玻璃高度", (height - 15.5).toFixed(1), glassNumber),
                    createData("窗芯玻璃宽度", ((width - 13.2)/2 - 0.6).toFixed(1), glassNumber)
                ];
            }else{
                rows = [
                    createData("边封", height, glassNumber * 2),
                    createData("长光企", (height - fix - 7).toFixed(1), glassNumber),
                    createData("短光企", (height - fix - 8.5).toFixed(1), glassNumber),
                    createData("长勾企", (height - fix - 7).toFixed(1), glassNumber),
                    createData("短勾企", (height - fix - 8.5).toFixed(1), glassNumber),
                    createData("上方", ((width - 13.2)/2).toFixed(1), glassNumber * 2),
                    createData("中方", ((width - 13.2)/2).toFixed(1), glassNumber),
                    createData("下方", ((width - 13.2)/2).toFixed(1), glassNumber),
                    createData("上固定", (width - 3).toFixed(1), glassNumber),
                    createData("中腰", (width - 3).toFixed(1), glassNumber),
                    createData("下轨", (width - 3).toFixed(1), glassNumber),
                    createData("盖板", (fix - 2.5).toFixed(1), glassNumber * 2),
                    createData("中柱", (fix - 2.5).toFixed(1), glassNumber),
                    createData("窗芯玻璃高度", (height - fix - 16.5).toFixed(1), "无"),
                    createData("窗芯玻璃宽度", ((width - 13.2)/2 - 0.6).toFixed(1), "无"),
                    createData("固定玻璃高度", (fix - 3.2).toFixed(1), "无"),
                    createData("不带中柱固定玻璃宽度", (width - 7).toFixed(1), "无"),
                    createData("带中柱固定玻璃宽度", ((width - 10)/2).toFixed(1), "无"),
                    createData("不带中柱压线", (width - 8.4).toFixed(1), "无"),
                    createData("带中柱压线", ((width - 10.5)/2).toFixed(1), "无"),
                ];
            }
        }else{
            if(!isFixed){
                rows = [
                    createData("边封", height, glassNumber * 2),
                    createData("平上滑", (width - 4).toFixed(1), glassNumber),
                    createData("下轨", (width - 4).toFixed(1), glassNumber),
                    createData("长光企", (height - 7).toFixed(1), glassNumber),
                    createData("短光企", (height - 8).toFixed(1), glassNumber),
                    createData("长勾企", (height - 7).toFixed(1), glassNumber),
                    createData("短勾企", (height - 8).toFixed(1), glassNumber),
                    createData("上方", ((width - 12.8)/2).toFixed(1), glassNumber * 2),
                    createData("中方", ((width - 12.8)/2).toFixed(1), glassNumber),
                    createData("下方", ((width - 12.8)/2).toFixed(1), glassNumber),
                    createData("窗芯玻璃高度", (height - 19.5).toFixed(1), glassNumber),
                    createData("窗芯玻璃宽度", ((width - 12.8)/2 - 0.9).toFixed(1), glassNumber)
                ];
            }else{
                rows = [
                    createData("边封", height, glassNumber * 2),
                    createData("上固定", (width - 4).toFixed(1), glassNumber),
                    createData("中腰", (width - 4).toFixed(1), glassNumber),
                    createData("下轨", (width - 4).toFixed(1), glassNumber),
                    createData("长光企", (height - fix - 7).toFixed(1), glassNumber),
                    createData("短光企", (height - fix - 8).toFixed(1), glassNumber),
                    createData("长勾企", (height - fix - 7).toFixed(1), glassNumber),
                    createData("短勾企", (height - fix - 8).toFixed(1), glassNumber),
                    createData("上方", ((width - 12.8)/2).toFixed(1), glassNumber * 2),
                    createData("下方", ((width - 12.8)/2).toFixed(1), glassNumber),
                    createData("盖板", (fix - 2.5).toFixed(1), glassNumber * 2),
                    createData("中柱", (fix - 2.5).toFixed(1), glassNumber),
                    createData("窗芯玻璃高度", (height - fix - 19.5).toFixed(1), "无"),
                    createData("窗芯玻璃宽度", ((width - 12.8)/2 - 0.9).toFixed(1), "无"),
                    createData("固定玻璃高度", (fix - 3.2).toFixed(1), "无"),
                    createData("不带中柱固定玻璃宽度", (width - 8).toFixed(1), "无"),
                    createData("带中柱固定玻璃宽度", ((width - 11)/2).toFixed(1), "无")
                ];
            }
        }

        let fir_style = {
            paddingRight: "0px",
            paddingLeft: "23px"
        }

        let sec_style = {
            paddingRight: "0px",
            paddingLeft: "0px",
            width: "80px"
        }

        return (
            <div className="result">
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <CustomTableCell style={fir_style}>名称</CustomTableCell>
                                <CustomTableCell align="left" style={sec_style}>规格(厘米)</CustomTableCell>
                                <CustomTableCell align="left" style={sec_style}>数量(支)</CustomTableCell>
                            </TableRow>
                        </TableHead>
                        
                            <TableBody>
                                {rows.map(row => (
                                    <TableRow key={row.id}>
                                        <CustomTableCell component="th" scope="row" style={fir_style}>
                                            {row.name}
                                        </CustomTableCell>
                                        <CustomTableCell align="left" style={sec_style}>{row.value}</CustomTableCell>
                                        <CustomTableCell align="left" style={sec_style}>{row.count}</CustomTableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        
                    </Table>
                </Paper>
                <Button variant="contained" color="secondary" onClick={() => this.props.updateCurrent("Home")} id="back-button">
                    返回
                </Button>
            </div>
        );
    }
}