import React, { Component } from "react";
import firebase from "firebase";
import FileUploader from "react-firebase-file-uploader";


const config = {
    apiKey: "AIzaSyCc_5NgrrrPFtc7aDgZhT8c052RGz5RkCA",
    authDomain: "test-d9c74.firebaseapp.com",
    databaseURL: "https://test-d9c74.firebaseio.com",
    projectId: "test-d9c74",
    storageBucket: "test-d9c74.appspot.com",
    messagingSenderId: "697431212590"
};

firebase.initializeApp(config);

var db = firebase.firestore();

class ProfilePage extends Component {
    state = {
        username: "",
        avatar: "",
        isUploading: false,
        progress: 0,
        avatarURL: ""
    };

    handleChangeUsername = event =>
        this.setState({ username: event.target.value });
    handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
    handleProgress = progress => this.setState({ progress });
    handleUploadError = error => {
        this.setState({ isUploading: false });
        console.error(error);
    };
    handleUploadSuccess = filename => {
        this.setState({ avatar: filename, progress: 100, isUploading: false });
        firebase
            .storage()
            .ref("images")
            .child(filename)
            .getDownloadURL()
            .then(url => {
                this.setState({ avatarURL: url });
                db.collection("test").add({
                    username: this.state.username,
                    url: this.state.avatarURL
                })
                    .then(function () {
                        console.log("Document successfully written!");
                    })
                    .catch(function (error) {
                        console.error("Error writing document: ", error);
                    })
            });

    };

    render() {
        return (
            <div>
                <form>
                    <label>Username:</label>
                    <input
                        type="text"
                        value={this.state.username}
                        name="username"
                        onChange={this.handleChangeUsername}
                    />
                    <br />
                    <label>Avatar:</label>
                    {this.state.isUploading && <p>Progress: {this.state.progress}</p>}
                    {this.state.avatarURL && <img src={this.state.avatarURL} style={{ width: "200px" }} />}
                    <br />
                    <br />
                    <br />
                    <label style={{ backgroundColor: 'steelblue', color: 'white', padding: 10, borderRadius: 4, pointer: 'cursor' }}>
                        Select your awesome avatar
                        <FileUploader
                            accept="image/*"
                            hidden
                            name="avatar"
                            storageRef={firebase.storage().ref("images")}
                            onUploadStart={this.handleUploadStart}
                            onUploadError={this.handleUploadError}
                            onUploadSuccess={this.handleUploadSuccess}
                            onProgress={this.handleProgress}
                        />
                    </label>
                </form>
            </div>
        );
    }
}
export default ProfilePage;