import React, { Component } from 'react';
import '../App.css';
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from "@material-ui/core/TextField";
import Chip from '@material-ui/core/Chip';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

export default class SizeTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            width: 0,
            height: 0,
            isFixed: false,
            fix: 0,
            glassNumber: 0,
            type: 1
        }
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    handleSelectChange = event => {
        this.setState({ type: event.target.value });
    };

    onCompleteInput() {
        this.props.updateData('width', this.state.width);
        this.props.updateData('height', this.state.height);
        this.props.updateData('isFixed', this.state.isFixed);
        this.props.updateData('glassNumber', this.state.glassNumber);
        this.props.updateData('fix', this.state.fix);
        this.props.updateData('type', this.state.type);
        this.props.updateCurrent("Result");
    }
    render() {
        const { isFixed } = this.state;
        if (!isFixed) {
            return (
                <div className="size-table">
                    <Chip
                        label="输入材料尺寸(cm)"
                        component="a"
                    />
                    <TextField
                        id="standard-textarea"
                        label="宽度"
                        type="search"
                        margin="normal"
                        onChange={e => this.setState({ width: e.target.value })}
                    />
                    <TextField
                        id="standard-textarea"
                        label="高度:"
                        type="search"
                        margin="normal"
                        onChange={e => this.setState({ height: e.target.value })}
                    />
                    <FormControl id="select-type">
                        <InputLabel>型号</InputLabel>
                        <Select
                            value={this.state.type}
                            onChange={this.handleSelectChange}
                            inputProps={{
                                name: "type"
                            }}
                        >
                            <MenuItem value={1}>828型</MenuItem>
                            <MenuItem value={2}>757型</MenuItem>
                            <MenuItem value={3}>80T断桥</MenuItem>
                        </Select>
                    </FormControl>
                    <div className="selector">
                        <Checkbox checked={isFixed} onChange={this.handleChange('isFixed')}/>
                        <label>是否带固定</label>
                    </div>
                    <TextField
                        disabled
                        id="standard-disabled"
                        label="固定高度:"
                        margin="normal"
                    />
                    <TextField
                        id="standard-textarea"
                        label="窗户数量:"
                        type="search"
                        margin="normal"
                        onChange={e => this.setState({ glassNumber: e.target.value })}
                    />
                    <Button variant="contained" color="secondary" onClick={() => this.onCompleteInput()} id="button">
                        完成
                </Button>
                </div>
            )
        } else {
            return (
                <div className="size-table">
                    <Chip
                        label="输入材料尺寸(cm)"
                        component="a"
                    />
                    <TextField
                        id="standard-textarea"
                        label="宽度"
                        type="search"
                        margin="normal"
                        onChange={e => this.setState({ width: e.target.value })}
                    />
                    <TextField
                        id="standard-textarea"
                        label="高度:"
                        type="search"
                        margin="normal"
                        onChange={e => this.setState({ height: e.target.value })}
                    />
                    <FormControl id="select-type">
                        <InputLabel>型号</InputLabel>
                        <Select
                            value={this.state.type}
                            onChange={this.handleSelectChange}
                            inputProps={{
                                name: "type"
                            }}
                        >
                            <MenuItem value={1}>828型</MenuItem>
                            <MenuItem value={2}>757型</MenuItem>
                            <MenuItem value={3}>80T断桥</MenuItem>
                        </Select>
                    </FormControl>
                    <div className="selector">
                        <Checkbox checked={isFixed} onChange={this.handleChange('isFixed')} value="isFixed" />
                        <label>是否带固定</label>
                    </div>
                    <TextField
                        id="standard-textarea"
                        label="固定高度:"
                        type="search"
                        margin="normal"
                        onChange={e => this.setState({ fix: e.target.value })}
                    />
                    <TextField
                        id="standard-textarea"
                        label="窗户数量:"
                        type="search"
                        margin="normal"
                        onChange={e => this.setState({ glassNumber: e.target.value })}
                    />
                    <Button variant="contained" color="secondary" onClick={() => this.onCompleteInput()} id="button">
                        完成
                </Button>

                </div>
            )
        }
    }
}
