import React, { Component } from 'react';
import './App.css';
import Result from './Components/Result';
import SizeTable from './Components/SizeTable';
import ProfilePage from './Components/ProfilePage';


export default class App extends Component {
  state = {
    width: 0,
    height: 0,
    isFixed: false,
    fix: 0,
    glassNumber: 0,
    type: 1,
    current: "Home"
  }

  updateCurrent(cur) {
    if (cur === "Home") {
      this.setState({
        width: 0,
        height: 0,
        isFixed: false,
        glassNumber: 0,
        current: "Home"
      });
    }
    if (cur === "Result") {
      this.setState({
        current: "Result"
      })
    }
  }

  updateData(name, value) {
    this.setState({
      [name]: value
    });
  }

  render() {
    if (this.state.current === "Home") {
      return (
        <div className="App">
          <header className="App-header">
            <p>尺寸计算器</p>
          </header>
          <body>
            <div className="top"></div>
            <SizeTable
              updateData={(name, value) => this.updateData(name, value)}
              updateCurrent={cur => this.updateCurrent(cur)}
            />
            <ProfilePage></ProfilePage>
            <div className="bottom"></div>
          </body>
          <div className="footer">
            <p>Written by Terry</p>
          </div>
        </div>
      );
    }
    if (this.state.current === "Result") {
      return (
        <div className="App">
          <header className="App-header">
            <p>尺寸计算器</p>
          </header>
          <div className="table-header"></div>
          <body>
            <Result
              width={this.state.width}
              height={this.state.height}
              isFixed={this.state.isFixed}
              glassNumber={this.state.glassNumber}
              type={this.state.type}
              fix={this.state.fix}
              updateCurrent={cur => this.updateCurrent(cur)}
            />
          </body>
          <div className="table-footer"></div>
          <div className="footer">
            <p>Written by Terry</p>
          </div>
          
        </div>
      )
    }
  }
}


